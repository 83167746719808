<template>

	<div v-if="!domainCeckHasFinished && isLoading && !errored" class=" grid h-screen place-items-center">
		<img class="h-48 w-48" src="https://i.imgur.com/zzRTDfw_d.webp?maxwidth=760&fidelity=grandf" />
	</div>
	<div v-if="errored" class="h-screen">
	<p class="text-xl font-medium m-20">⚠️ NETWORK ERROR ⚠️</p>
	</div>
	<AllDeals ref="alldeals" v-if="domainCeckHasFinished && isMainAppComponent"  :categoryList="categoryArray" :serverDomain="serverDomain[serverAccessNumber]" :serverIdNumber="serverAccessNumber" ></AllDeals> 
	<productBox v-if="domainCeckHasFinished && isProductCard" />
	<adminPage v-if="domainCeckHasFinished && isAdmin"/>
	<chart v-if="domainCeckHasFinished && isChartView" :asin="asinForChart" :serverDomain="serverDomain[serverAccessNumber]"/>
</template>

<script>
//:deals="deal"
//https://imgur.com/a/VNWwsrf
import AllDeals from "./components/AllDeals.vue";
import { register } from 'register-service-worker'
import productBox from './components/blog/productbox.vue'
import adminPage from './components/admin/adminPage.vue'
import chart from './components/chart.vue'

export default {
	name: "App",
	components: {
		AllDeals,
		productBox,
		adminPage,
		chart
	},
	data() {
		return {
			serverDomain:["https://api.piovonoofferte.it","https://piovonoofferte-api4.loca.lt","https://piovonoofferte-api4.loophole.site","https://piovonoofferte-api3.loophole.site","https://piovonoofferte-api3.loca.lt", "https://piovonoofferte-api2.loca.lt","https://piovonoofferte-api1.loca.lt", "http://129.152.7.192:7575"],
			serverAccessNumber:0,
			deal: [],
			isRetry : true,
			isLoading:true,
			errored:false,
			categoryArray:[],
			domainCeckHasFinished:false,
			promptEventValue:null,
			selectedServerDomain:null,
			isProductCard:false,
			isAdmin:false,
			isChartView:false,
			asinForChart:'',
			isMainAppComponent:true
		};
	},
	mounted() {
//---	
		let t = this
		register('/service-worker.js', {
		registrationOptions: { scope: './' },
		ready () {
			console.log('Service worker is active.')
		},
		registered () {
			console.log('Service worker has been registered.')
		},
		cached () {
			console.log('Content has been cached for offline use.')
		},
		updatefound () {
			console.log('New content is downloading.')
		},
		updated () {
			console.log('New content is available; please refresh.')
		},
		offline () {
			console.log('No internet connection found. App is running in offline mode.')
		},
		error (error) {
			console.error('Error during service worker registration:', error)
		}
		})
		addEventListener('beforeinstallprompt', function(e){ //funzione per installare pwa con pulsante interno
			// Stash the event so it can be triggered later.
			e.preventDefault();
			t.promptEventValue = e;  // asseganto vue this a una variabile perchè il this su un eventlistner si riferisce al DOM
			console.log("evento beforeinstallprompt")
		});
//-----
		this.selectServer() 
		//this.getCategory()

		if(window.location.href.includes("/productcard")){
			this.isMainAppComponent=false
			this.isProductCard = true
		}
		if(window.location.href.includes("/admin")){
			this.isMainAppComponent=false
			this.isAdmin = true
		}
		if(window.location.href.includes("/chart")){
			this.isMainAppComponent=false
			this.isChartView = true
			this.asinForChart = (new URLSearchParams(window.location.search)).get('chartasin')
		}
	},
	methods: {
		buttonInstallAppClicked(){
			console.log("bottone cliccato")
			this.promptEventValue.prompt()
		},
		selectServer(){
			this.$axios
			.get(`${this.serverDomain[this.serverAccessNumber]}`, { headers:{ "Bypass-Tunnel-Reminder": 2} }) // http://127.0.0.1:7575/dealsInRange?startRange=0&stopRange=500 https://piovonoofferte-api2.loca.lt/allDeals http://127.0.0.1:7575/getCategory
			.then((response) => {
				console.log(response)
				this.domainCeckHasFinished = true
				this.selectedServerDomain = this.serverDomain[this.serverAccessNumber]
				this.getCategory();
			})
			.catch((error) => {
				this.serverAccessNumber++;
				console.log(error);
				if(this.serverAccessNumber == this.serverDomain.length){
					this.errored = true;
				}else{
					this.selectServer()
				}
			})
			.finally(() => {console.log(this.serverDomain[this.serverAccessNumber]);
			this.loading = false});
		},
		getCategory(){
			this.$axios
			.get(`${this.serverDomain[this.serverAccessNumber]}/getCategory`, { headers:{ "Bypass-Tunnel-Reminder": 2} }) // http://127.0.0.1:7575/dealsInRange?startRange=0&stopRange=500 https://piovonoofferte-api2.loca.lt/allDeals http://127.0.0.1:7575/getCategory
			.then((response) => {
				//this.deal = response.data.deals;
				//this.orderPost()
				//this.formatText()
				//this.getAllCategory()
				this.categoryArray = response.data.category
				this.isLoading = false
				console.log(response.data);
			})
			.catch((error) => {
				console.log(error);
				this.errored = true
			})
			.finally(() => {this.loading = false
			this.errored = false});
		},
		orderPost: function () {
			function dynamicSort(property) {
				//sort by value, not my code
				var sortOrder = 1;
				if (property[0] === "-") {
					sortOrder = -1;
					property = property.substr(1);
				}
				return function (a, b) {
					var result =
						a[property] < b[property]
							? -1
							: a[property] > b[property]
							? 1
							: 0;
					return result * sortOrder;
				};
			}
			this.deal.sort((dynamicSort("dealPercentage"))).reverse();
		},
    formatText:function(){
      this.deal.forEach(element => {
        if (element.title.length > 60){
        element.title = element.title.slice(0,60) + "..."
        }
      });
    },
	getAllCategory(){
		this.deal.forEach(element => {
        if(this.categoryArray.indexOf(element.category) == -1){
			this.categoryArray.push(element.category)
		}
      });
	},

	},
};
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>