<template>
    <div v-if="post!=undefined" class="text-left" :class="{'lg:px-48': passedMd === undefined}">
      <Markdown class="mx-5" :breaks="true" :source="post.body  "></Markdown>
    </div>
  </template>
  
  <script>
  import Markdown from 'vue3-markdown-it'
  import 'highlight.js/styles/monokai.css';
  
  export default {
    name: "allPost",
    components: {
      Markdown,
    },
    props:{
        passedMd:String
    },
    data() {
      return {
        postidparam: (new URLSearchParams(window.location.search)).get('postid'),
        post:undefined
      };
    },
    mounted() {
      if (this.passedMd != undefined){
        this.post.body = this.passedMd
      }else{
        this.getPost()
      }
      setTimeout(()=>{this.manageStyle()}, 10)
    },
    methods: {
        getPost(){
            this.loading = true
			this.$axios
			.get(`${this.$root.selectedServerDomain}/getPostById?id=${this.postidparam}`, { headers:{ "Bypass-Tunnel-Reminder": 2} }) // http://127.0.0.1:7575/dealsInRange?startRange=0&stopRange=500 https://piovonoofferte-api2.loca.lt/allDeals
			.then((response) => {
				this.post = response.data.post
                this.loading = false
                document.title = `${this.post.title} | piovonoofferte.it` 
                setTimeout(()=>{this.manageStyle()}, 10)
			})
			.catch((error) => {
				console.log(error);
				this.errored = true;
			})
			.finally(() => {this.loading = false});
        },
        manageStyle(){
            var h1Elements = document.querySelectorAll('h1');
            if(h1Elements != undefined){
                for (var i = 0; i < h1Elements.length; i++) {
                    h1Elements[i].style.fontSize = '25px';
                    h1Elements[i].style.marginTop = '10px';
                    h1Elements[i].style.marginBottom = '10px';
                    h1Elements[i].classList.add('font-semibold')
                    var newDiv2 = document.createElement('div');
                    newDiv2.classList.add('border', 'border-gray-300');
                    h1Elements[i].appendChild(newDiv2);
                }
            }

            var h2Elements = document.querySelectorAll('h2');
            if(h2Elements != undefined){
                for (var m = 0; m < h2Elements.length; m++) {
                    h2Elements[m].style.fontSize = '18px';
                    h2Elements[m].style.marginTop = '15px';
                    h2Elements[m].classList.add('font-semibold')
                }
            }

            var h3Elements = document.querySelectorAll('h3');
            if(h3Elements != undefined){
                for (var o = 0; o < h3Elements.length; o++) {
                    h3Elements[o].style.fontSize = '13px';
                    h3Elements[o].style.marginTop = '5px';
                } 
            }

            var imgElements = document.querySelectorAll('img');
            if(imgElements != undefined){
                for (var n = 0; n < imgElements.length; n++) {
                    var newDiv = document.createElement('div');
                    newDiv.classList.add('imagecenter', 'flex', 'items-center', 'justify-center');
                    newDiv.appendChild(imgElements[n].cloneNode(true));
                    imgElements[n].parentNode.replaceChild(newDiv, imgElements[n]);
                }
            }

            document.querySelectorAll('p').forEach(paragrafo => {
            if (paragrafo.textContent.match(/^PIOVONOOFFERTETAG-PRODUCT-/)) {
                paragrafo.innerHTML = `<iframe class="mt-4" frameborder="0" height="250" width="100%" allowfullscreen src="/productcard?asin=${paragrafo.textContent.replace("PIOVONOOFFERTETAG-PRODUCT-","")}&smalltitle=true"></iframe>`;
            }
            });
        }
    }
  }
  </script>

<style>
.h_iframe iframe {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
}
</style>
  