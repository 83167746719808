<template>
    <button v-if="loaded" class="buttonDeal w-full bg-slate-100 rounded-xl p-2 ">
        <div class="bg-slate-100 rounded-xl my-1">
            <div class=" flex justify-between bg-slate-100 rounded-xl">
                <img class="object-centre object-contain h-32 w-32 bg-white m-2 rounded-xl p-4" :src="product.image" />
                <div class="pt-6 md:p-8 text-center w-full">
                    <p class="text-lg font-medium " >
                        {{ product.title }}
                    </p>
                    <div class="mt-1">

                        <div v-if="product.noDealPrice != null && product.noDealPrice > product.price" class="grid grid-cols-1 place-content-center place-items-center">
                            <div class="grid grid-cols-2 w-fit place-items-center place-content-center">
                                <s class=" text-slate-700 w-fit mx-1">
                                    {{ product.noDealPrice.toFixed(2) }}€
                                </s>
                                <p class="text-white bg-red-600 rounded w-fit px-1 mx-1 text-sm">
                                    -{{ parseInt(100 - (product.price * 100) / product.noDealPrice) }}%
                                </p>
                            </div>
                        </div>
                        <p class="text-sky-500">{{ product.price.toFixed(2) }}€</p>

                        <h1 v-if="product.hasCoupon" class="text-sky-500">
                            🎫
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <button
			class="bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-2 rounded-full"
			@click="openToAmazon(product)" :href="'https://amazon.it/dp/'+product.asin+'?tag=ronchitom_21'">Acquista ora su Amazon
			</button>
    </button>
</template>
  
<script>
export default {
    name: "productBox",
    data() {
        return {
            loaded:false,
            asin: (new URLSearchParams(window.location.search)).get('asin'),
            smallTitle: (new URLSearchParams(window.location.search)).get('smalltitle') == 'true' != undefined 
                        && (new URLSearchParams(window.location.search)).get('smalltitle') === 'true' ?true:false
        };
    },
    mounted() {
        this.loadData()
        console.log(this.smallTitle)
    },
    methods: {
        loadData(){
            this.loading = true
            this.$axios
			.get(`${this.$root.selectedServerDomain}/getProduct?asin=${this.asin}`, { headers:{ "Bypass-Tunnel-Reminder": 2} }) 
			.then((response) => {
                console.log(response)
				this.product = response.data.product
                if(this.smallTitle){
                    console.log('edff')
                    if (this.product.title.length > 50){
                        this.product.title = this.product.title.slice(0,50) + "..."
                    }
                }
                this.loading = false
                this.loaded=true
			})
			.catch((error) => {
				console.log(error);
				this.errored = true;
                this.loading = false
			})
			.finally(() => {this.loading = false});
        },
        openToAmazon(product){
			window.open(`https://www.amazon.it/dp/${product.asin}/?tag=ronchitom-21`, '_blank');
		},
    }
}
</script>

<style></style>
  