<template>
    <div class="flex ">
      <div class="w-1/2 p-4">
        <h2 class="text-2xl mb-4">Scrivi in Markdown</h2>
        <textarea v-model="inputText" class="border p-2 w-full h-48" @input="inputF()"></textarea>
      </div>
      <div class="w-1/2 p-4">
        <h2 class="text-2xl mb-4">Markdown Elaborato</h2>
        <singlepost :passedMd="inputText" :key="inputText"/>
      </div>
    </div>
  </template>
  
  <script>
  import singlepost from '../blog/singlepost.vue'
  export default {
    data() {
      return {
        inputText: '',
      };
    },
    name:'mdEditor',
    components:{
        singlepost
    },
    computed: {
      parsedMarkdown() {
        // Implementa la logica per elaborare il testo in markdown
        return this.inputText; // Per ora restituisci il testo originale
      },
    },
    methods:{
        inputF(){
            console.log(this.inputText)

        }
    }
  };
  </script>
  
  <style scoped>
  /* Stili opzionali per personalizzare il componente di gestione del markdown */
  </style>
  