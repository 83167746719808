
<template>
    <singlepostview v-if="postid != null"/>
    <div v-if="postid === null">
        <div class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
        <button v-for="post in posts" :key="post" class="w-full  mt-6 rounded-lg overflow-hidden bg-blue-50 shadow-sm border " @click="openPost(post.id)" :href="'https://piovonoofferte.it/blog?postid='+post.id">
            <img v-if="post.main_img != null" class="w-full h-32 object-cover" :src="post.imageurl" alt="Immagine dell'articolo" />
            <div class="px-6 py-4">
                <div class="font-bold text-l mb-2">{{post.title}}</div>
                <div class="text-sm mb-2">Data di pubblicazione: {{post.date}}</div>
            </div>
        </button>
    </div>
    </div>
</template>
    
<script>
import singlepostview from './singlepost.vue'
    export default {
        name: "allPost",
        components:{
            singlepostview
        },
        data() {
            return {
                posts:[],
                postid: null
            };
        },
        async mounted() {
            this.postid = await (new URLSearchParams(window.location.search)).get('postid')
            document.title = `Blog | piovonoofferte.it`
            this.getPosts()
        },
        methods: {
            getPosts(){
                this.loading = true
                this.$axios
                .get(`${this.$root.selectedServerDomain}/getPosts?start=0&stop=10000`, { headers:{ "Bypass-Tunnel-Reminder": 2} }) // http://127.0.0.1:7575/dealsInRange?startRange=0&stopRange=500 https://piovonoofferte-api2.loca.lt/allDeals
                .then((response) => {
                    this.posts = response.data.posts
                    this.loading = false
                })
                .catch((error) => {
                    console.log(error);
                    this.errored = true;
                })
                .finally(() => {this.loading = false});
            },
            openPost(id){
                window.open(`https://piovonoofferte.it/blog?postid=${id}`); //'_blank'
            }
        }
    }
    </script>
    